import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Tooltip, Badge, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  badge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      right: 15,
    },
  },
  hideBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      display: "none !important",
    },
  },
}));

const borderMap = {
  Reviewed: "1px solid lightgrey",
  Edited: "1px solid lightgrey",
  Pending: "1px solid lightgrey",
};

const borderColorMap = {
  Reviewed: "1px solid lightgrey",
  Edited: "1px solid lightgrey",
  Pending: "none",
};

const ProductDetailEditDropdown = ({
  attribute,
  index,
  onChangeSelectAttribute,
  reset,
}) => {
  const defaultValue =
    attribute.values.length > 0
      ? attribute.values.find((item) => item.selected)
      : { value: "", label: "" };
  const nonEditable = defaultValue?.tenant_attribute_value_id === -1;
  const [val, setVal] = useState(defaultValue);
  const classes = useStyles();
  useEffect(() => {
    if (reset) {
      setVal(defaultValue);
    }
  }, [reset]);

  const dropdownStyles = {
    indicatorSeparator: (base) => ({
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      zIndex: "10 !important",
    }),
    control: (base) => ({
      ...base,
      pointerEvents: nonEditable ? "none" : "all",
      backgroundColor: nonEditable ? "#d3d3d382" : "#fff",
      border: "1px solid lightgrey",
      borderLeft: `${
        val?.value === defaultValue?.value
          ? borderMap[attribute?.product_attribute_status]
          : "8px solid orange"
      }`,
      boxShadow: val?.value !== defaultValue?.value ? "orange" : "lightgrey",
      "&:hover": {
        borderColor:
          val?.value !== defaultValue?.value
            ? "orange !important"
            : borderColorMap[attribute?.product_attribute_status],
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: nonEditable && "none",
      color: "inherit",
      padding: "0px !important",
      paddingRight: "5px !important",
      marginTop: "6px",
    }),
  };

  return (
    <Tooltip
      title={nonEditable ? "Cannot edit this value!" : ""}
      placement="right"
      arrow
    >
      <div key={index}>
        <Badge
          badgeContent={attribute["mapping_strategy"]}
          color={"primary"}
          className={clsx(classes.badge, classes.hideBadge)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Select
            id="productDetaildropdown"
            options={attribute.values.map((el) =>
              el.value ? el : { ...el, label: "Unassigned" }
            )}
            styles={dropdownStyles}
            className="product-detail-attribute-select"
            isSearchable={false}
            isClearable={false}
            name="product-attribute-edit"
            value={val?.label ? val : { ...val, label: "Unassigned" }}
            onChange={(obj) => {
              setVal(obj);
              defaultValue.value === obj.value
                ? onChangeSelectAttribute(obj, attribute, "delete")
                : onChangeSelectAttribute(obj, attribute, "add");
            }}
          />
        </Badge>
      </div>
    </Tooltip>
  );
};

export default ProductDetailEditDropdown;
