import React, { useState, useEffect } from "react";
import ProductDetailButton from "./ProductDetailButton";
import { Tab, Tabs } from "@material-ui/core";
import axiosInstance from "../../../../../Utils/axios";
import LoadingOverlay from "../../../../../Utils/Loader/loader";
import { update } from "lodash";


const description = {
    '70389':{
    0:"Experience the timeless taste of Lay's Classic Potato Chips, made with farm-grown potatoes cooked and seasoned to perfection. Every crispy chip is packed with the fresh flavor of potatoes, delivering happiness in every bite. Perfectly suited for family gatherings, movie nights, or celebrations, this Family Size bag ensures there's plenty to share with everyone.\n" +
        "\n" +
        "Crafted with care, Lay's chips are guaranteed fresh and made using simple, wholesome ingredients: potatoes, vegetable oil (sunflower, corn, or canola), and a touch of salt. Naturally gluten-free, these chips are an ideal snack choice for anyone seeking delicious and worry-free indulgence.\n" +
        "\n" +
        "With over 75 years of rich history and a flavor loved by all, Lay's Classic Potato Chips bring joy and smiles to every occasion. Stock up now and enjoy the crisp, irresistible taste that has made Lay's a household favorite for generations.\n" +
        "\n" +
        "Brand: Lay's\n" +
        "Flavor: Classic Corn\n" +
        "Pack Type: Bag\n" +
        "Pack Size: Family Size\n" +
        "Key Features: Guaranteed Fresh, Gluten-Free\n" +
        "Ingredients: Potatoes, vegetable oil, and salt\n" +
        "Whether it’s a party or a quiet moment at home, Lay's Classic Potato Chips are the perfect snack companion. Grab a bag and savor the classic taste that never goes out of style!",
    1: 'Experimenta el sabor atemporal de las Papas Fritas Clásicas Lay\'s, hechas con papas cultivadas en granjas, cocinadas y sazonadas a la perfección. Cada crujiente chip está lleno del fresco sabor de las papas, brindando felicidad en cada bocado. Perfectas para reuniones familiares, noches de cine o celebraciones, esta bolsa tamaño familiar asegura que haya suficiente para compartir con todos.\n' +
        '\n' +
        'Elaboradas con cuidado, las papas fritas Lay\'s son garantizadas frescas y hechas con ingredientes simples y naturales: papas, aceite vegetal (girasol, maíz o canola) y un toque de sal. Naturalmente sin gluten, estas papas son una elección ideal para quienes buscan un bocadillo delicioso y sin preocupaciones.\n' +
        '\n' +
        'Con más de 75 años de rica historia y un sabor amado por todos, las Papas Fritas Clásicas Lay\'s traen alegría y sonrisas a cada ocasión. Abastece tu despensa ahora y disfruta del sabor crujiente e irresistible que ha convertido a Lay\'s en un favorito del hogar durante generaciones.\n' +
        '\n' +
        'Marca: Lay\'s\n' +
        'Sabor: Clásico de Maíz\n' +
        'Tipo de Empaque: Bolsa\n' +
        'Tamaño del Empaque: Tamaño Familiar\n' +
        'Características Clave: Garantizadas Frescas, Sin Gluten\n' +
        'Ingredientes: Papas, aceite vegetal y sal\n' +
        'Ya sea en una fiesta o en un momento tranquilo en casa, las Papas Fritas Clásicas Lay\'s son el compañero perfecto para tus antojos. ¡Consigue una bolsa y saborea el clásico sabor que nunca pasa de moda!\n',
},
    '70392':{0:"Discover the wholesome and delicious taste of SkinnyPop Popcorn, starting with premium popcorn kernels, sunflower oil, and just the right amount of salt. Perfectly popped to deliver Pure Popped Perfection, SkinnyPop is the guilt-free snack you've been craving.\n" +
            "\n" +
            "With no GMOs, gluten, preservatives, or artificial ingredients, SkinnyPop provides a light and satisfying crunch you can enjoy anytime. It’s also dairy-free, peanut-free, and Non-GMO, making it a versatile and safe choice for everyone in the family.\n" +
            "\n" +
            "Brand: SkinnyPop\n" +
            "Flavor: Classic Corn\n" +
            "Pack Type: Bag\n" +
            "Key Features:\n" +
            "Non-GMO\n" +
            "Gluten-Free\n" +
            "Dairy-Free\n" +
            "Peanut-Free\n" +
            "No Artificial Ingredients\n" +
            "SkinnyPop Popcorn is the ultimate better-for-you snack that doesn’t compromise on taste. Grab a bag today and enjoy a snack that's both simple and extraordinary!",
        1:"Descubre el sabor saludable y delicioso de las Palomitas de Maíz SkinnyPop, hechas con granos de maíz premium, aceite de girasol y la cantidad perfecta de sal. Perfectamente reventadas para ofrecer una Perfección Pura Reventada, SkinnyPop es el snack sin culpa que has estado buscando.\n" +
            "\n" +
            "Sin OGMs, gluten, conservantes ni ingredientes artificiales, SkinnyPop brinda un crujido ligero y satisfactorio que puedes disfrutar en cualquier momento. Además, es libre de lácteos, maní y no contiene OGMs, lo que lo convierte en una opción versátil y segura para toda la familia.\n" +
            "\n" +
            "Marca: SkinnyPop\n" +
            "Sabor: Maíz Clásico\n" +
            "Tipo de Empaque: Bolsa\n" +
            "Características Clave:\n" +
            "Sin OGMs\n" +
            "Sin Gluten\n" +
            "Sin Lácteos\n" +
            "Sin Maní\n" +
            "Sin Ingredientes Artificiales\n" +
            "Las Palomitas de Maíz SkinnyPop son el snack perfecto y mejor para ti que no sacrifica el sabor. ¡Consigue una bolsa hoy y disfruta de un bocadillo que es tanto simple como extraordinario!"

    },
    '70393':{0:'Bring the magic of movie theaters to your home with Pop Secret Microwaveable Popcorn. Indulge in the slightly sweeter butter flavor and classic crunch that will elevate your movie nights. Every kernel bursts with deliciousness for a perfect snacking experience.\n' +
            '\n' +
            'Brand: Pop Secret\n' +
            'Flavor: Slightly Sweeter Butter\n' +
            'Pack Type: Bag\n' +
            'Key Features:\n' +
            'Tastes like theater popcorn\n' +
            'Includes 30 bags\n' +
            'Classic crunch\n' +
            'Warnings: Open carefully after popping, as the bag may be hot.\n' +
            'Enjoy the irresistible taste of Pop Secret – your ultimate movie-night companion!\n' +
            '\n',
        1:'Lleva la magia de los cines a tu hogar con Palomitas de Maíz Microondas Pop Secret. Disfruta del delicioso sabor ligeramente dulce de mantequilla y del crujido clásico que harán tus noches de cine inolvidables. Cada grano estalla con sabor delicioso para una experiencia perfecta de bocadillos.\n' +
            '\n' +
            'Marca: Pop Secret\n' +
            'Sabor: Mantequilla Ligeramente Dulce\n' +
            'Tipo de Empaque: Bolsa\n' +
            'Características Clave:\n' +
            'Sabor como las palomitas de cine\n' +
            'Incluye 30 bolsas\n' +
            'Crujido clásico\n' +
            'Advertencias: Ábralo con cuidado después de cocinar, ya que la bolsa puede estar caliente.\n' +
            'Disfruta del sabor irresistible de Pop Secret: tu compañero ideal para noches de película.'},
    '70391':{0:'Take your snack game to the next level with Slim Jim Smoked Snack Sticks. Packed with 6g of protein per serving, these bold-flavored snacks are perfect for any time and anywhere. Individually wrapped, they\'re easy to carry and enjoy on the go.\n' +
            '\n' +
            'Brand: Slim Jim\n' +
            'Flavor: Bold Original\n' +
            'Pack Type: Box\n' +
            'Key Features:\n' +
            '6g protein per serving\n' +
            'Individually wrapped for convenience\n' +
            'Includes 120-count snack sticks\n' +
            'Warnings: Contains soy.\n' +
            'Whether it’s snack time, lunch, or after school, Slim Jim is your tasty energy booster.',
        1:'Lleva tus bocadillos al siguiente nivel con los Palitos de Snack Ahumados Slim Jim. Con 6g de proteína por porción, estos bocadillos de sabor intenso son perfectos para cualquier momento y lugar. Individualmente empacados, son fáciles de llevar y disfrutar mientras te mueves.\n' +
            '\n' +
            'Marca: Slim Jim\n' +
            'Sabor: Original Intenso\n' +
            'Tipo de Empaque: Caja\n' +
            'Características Clave:\n' +
            '6g de proteína por porción\n' +
            'Empaque individual para mayor conveniencia\n' +
            'Incluye 120 palitos de snack\n' +
            'Advertencias: Contiene soya.\n' +
            'Ya sea a la hora del almuerzo, la merienda o después de clases, Slim Jim es tu fuente de energía deliciosa.\n' +
            '\n'},
    '70396':{0:'Enjoy a healthy, convenient, and flavorful snack with Wonderful Pistachios Roasted & Salted. Grown in California’s San Joaquin Valley, these pistachios are carefully roasted and salted to perfection, offering the same great taste with none of the work.\n' +
            '\n' +
            'Brand: Wonderful\n' +
            'Flavor: Roasted & Salted\n' +
            'Pack Type: Bag\n' +
            'Key Features:\n' +
            'California-grown pistachios\n' +
            'Non-GMO Project Verified\n' +
            'Gluten-free, Kosher, and Halal certified\n' +
            'Rich in plant-based protein\n' +
            'Perfect for snacking or enhancing your recipes, Wonderful Pistachios are a smart, healthy choice.',1:'Disfruta de un snack saludable, práctico y lleno de sabor con Pistachos Tostados y Salados Wonderful. Cultivados en el fértil Valle de San Joaquín, California, estos pistachos se tuestan y salan a la perfección, ofreciendo el mismo gran sabor con menos trabajo.\n' +
            '\n' +
            'Marca: Wonderful\n' +
            'Sabor: Tostados y Salados\n' +
            'Tipo de Empaque: Bolsa\n' +
            'Características Clave:\n' +
            'Pistachos cultivados en California\n' +
            'Certificados por el Proyecto Non-GMO\n' +
            'Sin gluten, Kosher y Halal certificados\n' +
            'Ricos en proteína vegetal\n' +
            'Perfectos para picar o mejorar tus recetas, los pistachos Wonderful son una elección inteligente y saludable.'},
    '70398':{0:'Savor the delicious flavor of slow-cooked, hardwood-smoked Jack Link’s Beef Jerky, complemented by a hint of garlic. With 12g of protein and only 80 calories per serving, this snack is both tasty and nutritious.\n' +
            '\n' +
            'Brand: Jack Link’s\n' +
            'Flavor: Hardwood Smoked with Garlic\n' +
            'Pack Type: Bag\n' +
            'Key Features:\n' +
            '90% fat-free\n' +
            '12g protein per serving\n' +
            'Fresh lock zipper for lasting freshness\n' +
            'Includes 12.5 oz. of beef jerky\n' +
            'Warnings: Contains soy and wheat.\n' +
            'Perfect for on-the-go snacking, Jack Link’s Beef Jerky keeps you fueled and satisfied.',1:'Disfruta del delicioso sabor de la cecina de res Jack Link’s, cocinada lentamente y ahumada con madera de calidad, con un toque de ajo. Con 12g de proteína y solo 80 calorías por porción, este bocadillo es tanto sabroso como nutritivo.\n' +
            '\n' +
            'Marca: Jack Link’s\n' +
            'Sabor: Ahumado con Madera y Ajo\n' +
            'Tipo de Empaque: Bolsa\n' +
            'Características Clave:\n' +
            '90% libre de grasa\n' +
            '12g de proteína por porción\n' +
            'Cierre fresco para mantener la frescura\n' +
            'Incluye 12.5 oz. de cecina de res\n' +
            'Advertencias: Contiene soya y trigo.\n' +
            'Ideal para meriendas sobre la marcha, la cecina de res Jack Link’s te mantiene lleno y satisfecho.'},
    // '70393':{0:'',1:''},
    // '70393':{0:'',1:''},
    // '70393':{0:'',1:''},
    // '70393':{0:'',1:''},
    // '70393':{0:'',1:''},
}

const ProductDetailActions = ({
  saveEdits,
  handlePath,
  onChangeSelectAttribute,
  loading,
  initialLoadData,
  updatedLabelData,
  parentDispatch,
                                  productId
}) => {
  const [activeTabValue, setActiveTabValue] = useState(0);
  const [showTab, setShowTab] = useState(false);
  const [desObject, setDesObject] = useState({
    english: "",
    // french: "",
    spanish: "",
    // portugese: "",
  });

  const handleTabValueChange = (event, value) => {
    setActiveTabValue(value);
  };
  const styledTextArea = {
    width: "70%",
    height: "180px",
  };

  useEffect(() => {
    let desObject = JSON.parse(localStorage.getItem("itemDesObject"));
    if (desObject && desObject[initialLoadData?.id]) {
      setDesObject({
        ...desObject[initialLoadData?.id],
      });
      setShowTab(true);
    } else {
    }
  }, [activeTabValue, updatedLabelData]);

  // const initialPayload = () => {
  //   let payload = [];
  //   let currentDesc = JSON.parse(localStorage.getItem("itemDesObject"));
  //   //Adding categories and sub categories to the resultant payload
  //   for (let i = 0; i < initialLoadData.L1_L2_categories.length; i++) {
  //     const category = initialLoadData.L1_L2_categories[i];
  //     if (category.selected === true) {
  //       payload.push({ Category: category.value });
  //       console.log("Payload here", payload);
  //       for (let j = 0; j < category["Sub Category"].length; j++) {
  //         if (category["Sub Category"][j].selected === true) {
  //           payload.push({ "Sub Category": category["Sub Category"][j].label });
  //           break;
  //         }
  //       }
  //       break;
  //     }
  //   }
  //   //Adding rest of the attributes in payload
  //   for (let i = 0; i < initialLoadData.attributes.length; i++) {
  //     const attribute = initialLoadData.attributes[i];
  //     for (let j = 0; j < attribute.values.length; j++) {
  //       if (attribute.values[j].selected === true) {
  //         payload.push({ [`${attribute.label}`]: attribute.values[j].label });
  //         break;
  //       }
  //     }
  //   }
  //   if (updatedLabelData.length !== 0) {
  //     console.log("Updated Labels present here", updatedLabelData);
  //     for (const key in updatedLabelData) {
  //       let curr_item = updatedLabelData[key];
  //       let cur_item_attribute = curr_item.tenant_attribute_value;
  //       for (let j = 0; j < payload.length; j++) {
  //         let payload_attribute_val = Object.keys(payload[j])[0];
  //         if (cur_item_attribute === payload_attribute_val) {
  //           payload[j][payload_attribute_val] = curr_item.new_value;
  //         }
  //       }
  //     }
  //   }
  //   const updated_payload = payload.filter((item) => {
  //     const final_attribute_value = Object.keys(item)[0];
  //     // console.log(item[final_attribute_value] !== 'No value')
  //     if (item[final_attribute_value] !== "No value") {
  //       return item;
  //     }
  //   });
  //
  //   return {
  //     json_data: updated_payload,
  //     description:
  //       currentDesc && currentDesc[initialLoadData?.id]
  //         ? currentDesc[initialLoadData?.id].english
  //         : initialLoadData?.input_text[0]?.text,
  //   };
  // };

  // const generateDescriptionHandler = async () => {
  //   parentDispatch({ type: "START_LOADING" });
  //   let desLocalstorageObject = JSON.parse(
  //     localStorage.getItem("itemDesObject")
  //   );
  //   if (
  //     desLocalstorageObject &&
  //     desLocalstorageObject[initialLoadData?.id] &&
  //     desLocalstorageObject[initialLoadData?.id]?.english
  //   ) {
  //     localStorage.setItem(
  //       "itemDesObject",
  //       JSON.stringify({
  //         ...desLocalstorageObject,
  //         [initialLoadData?.id]: {
  //           ...desLocalstorageObject[initialLoadData?.id],
  //           english: localStorage.getItem("currentDesc"),
  //         },
  //       })
  //     );
  //   }
  //   console.log("initial Data---->", initialLoadData);
  //   console.log("new data ----->", updatedLabelData);
  //   const payload = initialPayload();
  //   try {
  //     const result = await axiosInstance.post(
  //       "/attribute-smart/generate-product-description/",
  //       payload
  //     );
  //     localStorage.setItem("currentDesc", result?.data?.data?.description);
  //     localStorage.setItem(
  //       "itemDesObject",
  //       JSON.stringify({
  //         ...desLocalstorageObject,
  //         [initialLoadData?.id]: {
  //           english: result?.data?.data?.description,
  //           french: "",
  //           spanish: "",
  //           portugese: "",
  //         },
  //       })
  //     );
  //     console.log("Api Response here", result);
  //     setDesObject({
  //       ...desObject,
  //       english: result?.data?.data?.description,
  //       french: "",
  //       spanish: "",
  //       portugese: "",
  //     });
  //     setShowTab(true);
  //     parentDispatch({ type: "STOP_LOADING" });
  //   } catch (err) {
  //     alert("Error Generating Description. Please Try Again");
  //     setShowTab(false);
  //     parentDispatch({ type: "STOP_LOADING" });
  //   }
  // };

    const TabPanel = (props) => {
        const { children, value } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== activeTabValue}
                id={`tabpanel-${value}`}
                aria-labelledby={`tab-${value}`}
                style={{ width: "100%", padding: 10 }}
            >
                {value === activeTabValue && <>{children}</>}
            </div>
        );
    };

  return (
    <>
      {/*<div*/}
      {/*  style={{*/}
      {/*    display: "flex",*/}
      {/*    paddingTop: "10px",*/}
      {/*    justifyContent: "space-between",*/}
      {/*    margin: "0 20px 0 20px",*/}
      {/*    padding: "30px 0 40px 0",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div>*/}
      {/*    <ProductDetailButton*/}
      {/*      label="Generate Description"*/}
      {/*      onClickHandler={generateDescriptionHandler}*/}
      {/*      type={1}*/}
      {/*      loading={loading}*/}
      {/*      id="saveBtn"*/}
      {/*    />*/}
      {/*    &nbsp;&nbsp;*/}
      {/*    <ProductDetailButton*/}
      {/*      label="Translate"*/}
      {/*      onClickHandler={translateDescriptionHandler}*/}
      {/*      type={1}*/}
      {/*      loading={loading}*/}
      {/*      id="cancelBtn"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div style={{ marginLeft: "20px", display: "flex", flexDirection:"column", gap:"0.5rem" }}>
        Product Description
          <Tabs
              value={activeTabValue}
              onChange={handleTabValueChange}
              aria-label="selected-language-tab"
          >
              <Tab label="English" value={0} />
              <Tab label="Spanish" value={1} />
          </Tabs>
          <TabPanel value={activeTabValue}>
          <textarea
            style={styledTextArea}
            onChange={(e) => {
              localStorage.setItem("currentDesc", e.target.value);
              let desLocalstorageObject = JSON.parse(
                localStorage.getItem("itemDesObject")
              );
              if (desLocalstorageObject) {
                localStorage.setItem(
                  "itemDesObject",
                  JSON.stringify({
                    ...desLocalstorageObject,
                    [initialLoadData?.id]: {
                      ...desLocalstorageObject[initialLoadData?.id],
                      english: e.target.value,
                    },
                  })
                );
              }
            }}
            defaultValue={desObject.english}
            value={description?.[productId]?.[activeTabValue]}
          />
          </TabPanel>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "10px",
          justifyContent: "space-between",
          margin: "0 20px 0 20px",
          padding: "30px 0 40px 0",
        }}
      >
        <div>
          <ProductDetailButton
            label="Save & Next"
            onClickHandler={() => saveEdits()}
            type={1}
            loading={loading}
            id="saveBtn"
          />
          &nbsp;&nbsp;
          <ProductDetailButton
            label="Cancel"
            onClickHandler={() => onChangeSelectAttribute("", {}, "reset")}
            type={0}
            loading={loading}
            id="cancelBtn"
          />
        </div>
        <ProductDetailButton
          label="Go Back"
          onClickHandler={handlePath}
          type={0}
          loading={loading}
          id="goBackBtn"
        />
      </div>
    </>
  );
};

export default ProductDetailActions;
